/**
 *
 */
export const yesNo = () => {
  return ['YES', 'NO'];
};

export const projectState = () => {
  return ['ACTIVE', 'FINISHED'];
};

export const AuthorizableActions = {
  LIST: 'LIST',
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const ResourceType = {
  ACADEMY: 'ACADEMY',
  APPLICANT: 'APPLICANT',
  APPOINTMENT: 'APPOINTMENT',
  APPOINTMENT_CAPACITY: 'APPOINTMENT_CAPACITY',
  AUDIT: 'AUDIT',
  BATTERY: 'BATTERY',
  CALENDAR: 'CALENDAR',
  CLASS_CONTENT: 'CLASS_CONTENT',
  CLIENT: 'CLIENT',
  COURSE: 'COURSE',
  DOCUMENT: 'DOCUMENT',
  ELECTRICIAN: 'ELECTRICIAN',
  EMPLOYEE: 'EMPLOYEE',
  EMPLOYER: 'EMPLOYER',
  ENERGY_FLOW_DIRECTION_SENSOR: 'ENERGY_FLOW_DIRECTION_SENSOR',
  HUMAN_RESOURCE: 'HUMAN_RESOURCE',
  INSTALLATION: 'INSTALLATION',
  INVERTER: 'INVERTER',
  ITEM: 'ITEM',
  ITEM_STORAGE: 'ITEM_STORAGE',
  MATERIAL: 'MATERIAL',
  MEASUREMENT_POINT_OPERATOR: 'MEASUREMENT_POINT_OPERATOR',
  OFFER: 'OFFER',
  POLICY: 'POLICY',
  POST: 'POST',
  POWER_COMPANY: 'POWER_COMPANY',
  PROJECT: 'PROJECT',
  REPORT: 'REPORT',
  REPORT_TEMPLATE_IMAGE: 'REPORT_TEMPLATE_IMAGE',
  SALES: 'SALES',
  SOLAR_PANEL: 'SOLAR_PANEL',
  STUDENT: 'STUDENT',
  SYSTEM: 'SYSTEM',
  TASK: 'TASK',
  TEACHING_MATERIAL: 'TEACHING_MATERIAL',
  TIME_TRACKING: 'TIME_TRACKING',
  TIMETABLE: 'TIMETABLE',
  TRAINER: 'TRAINER',
  UPDATE: 'UPDATE',
  USER: 'USER',
  WALLBOX: 'WALLBOX',
};

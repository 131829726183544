import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Auth
import Store from '../store';
import { isAuthenticated } from '../mixins/auth';
import { getAuthToken } from '../helpers/localStorage';
import {
  hasRole,
  authorize,
  authorizeByUser,
  hasResourcePermission,
} from '../helpers/userCapabilities';

// Layouts
import Layout from '@/components/Layout/Layout';
import MobileLayout from '@/components/Layout/MobileLayout';
import Print from '@/components/Layout/Print';

// Views
import Login from '../views/Login/CustomLogin.vue';

import { isMobile } from '@/helpers/browser.js';

// Import enums for resource-based access control
import { ResourceType, AuthorizableActions } from '../helpers/enums.js';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      const user = Store.getters['auth/getCurrentUser'];
      // console.log('User role: ' + user.role + ' - Required role:' + to.meta.requiredRole);

      if (isMobile()) {
        next({ name: 'MobileProjectsPage' });
      } else {
        next({ name: 'ProjectsPaginatedPage' });
      }
    },
  },
  {
    path: '*',
    component: () => import('@/views/Error/404'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/Error/403'),
  },
  {
    path: '/',
    component: Print,
    meta: {
      requiresAuthentication: true,
    },
    children: [
      {
        path: '/projekte/:projectNumber/drucken',
        name: 'ProjectPrintPage',
        component: () => import('@/views/Projects/ProjectPrint'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Project :projectNumber Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/drucken/:documentNumber',
        name: 'ProjectDocumentPrintPage',
        component: () => import('@/views/Projects/ProjectDocumentPrint'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Project :projectNumber Document Print',
        },
      },
      {
        path: '/angebote/:offerNumber/drucken',
        name: 'OfferPrintPage',
        component: () => import('@/views/Offers/OfferPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Offer Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/berichte/drucken',
        name: 'ReportPrintPage',
        component: () => import('@/views/Report/ReportPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Report Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/aufmass/drucken',
        name: 'MeasurementPrintPage',
        component: () => import('@/views/Measurement/MeasurementPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Measurment Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/service/drucken',
        name: 'ServicePrintPage',
        component: () => import('@/views/Service/ServicePrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Service Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/gutachten/drucken',
        name: 'AssessmentPrintPage',
        component: () => import('@/views/Assessment/AssessmentPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Assessment Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/angebot/drucken',
        name: 'ProjectOfferPrintPage',
        component: () => import('@/views/Projects/ProjectQcellsOfferPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Project :projectNumber Offer Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/materialliste/drucken',
        name: 'ConsumablesListPrintPage',
        component: () => import('@/views/Projects/ConsumablesListPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Consumables List ',
        },
      },
    ],
  },
  {
    path: '/mobile',
    component: MobileLayout,
    meta: {
      requiresAuthentication: true,
    },
    children: [
      /**
       * Mobile Project search
       */
      {
        path: 'projekte',
        name: 'MobileProjectsPage',
        component: () => import('@/views/Projects/MobileProjects'),
        meta: {
          authorizedRoles: ['INSTALLATION', 'TEAM_DC', 'TEAM_AC', 'ADMIN', 'CLIENT'],
          title: 'Mobile Projects ',
        },
      },
    ],
  },
  {
    path: '/app',
    component: Layout,
    meta: {
      requiresAuthentication: true,
    },
    children: [
      /**
       * Projekte
       */
      {
        path: '/projekte',
        name: 'ProjectsPaginatedPage',
        component: () => import('@/views/Projects/ProjectsPaginated'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'GUEST'],
          resourceType: ResourceType.PROJECT,
          requiredActionPermissions: [AuthorizableActions.LIST, AuthorizableActions.READ],
          title: 'Projekte',
        },
      },
      {
        path: '/projekte/:projectNumber/bearbeiten',
        name: 'ProjectEditPage',
        component: () => import('@/views/Projects/ProjectEdit'),
        props: (route) => ({
          projectNumber: route.params.projectNumber,
          projectTab: route.query.projectTab,
        }),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'GUEST'],
          title: 'Projekt :projectNumber bearbeiten',
          resourceType: ResourceType.PROJECT,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/projekte/neu',
        name: 'ProjectCreatePage',
        component: () => import('@/views/Projects/ProjectEdit'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Projekt erstellen',
          resourceType: ResourceType.PROJECT,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      /**
       * Lager
       */
      {
        path: '/material/lager',
        name: 'ItemStoragesPage',
        component: () => import('@/views/Items/ItemStorages'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Lager',
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/material/lager/:itemStorageNumber/bearbeiten',
        name: 'ItemStorageEditPage',
        component: () => import('@/views/Items/ItemStorageEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Lager :itemStorageNumber bearbeiten',
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/material/lager/neu',
        name: 'ItemStorageCreatePage',
        component: () => import('@/views/Items/ItemStorageEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Lager erstellen',
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Material Stammdaten
       */
      {
        path: '/material/stammdaten',
        name: 'ItemsPage',
        component: () => import('@/views/Items/Items'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Material',
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/material/stammdaten/:itemNumber/bearbeiten',
        name: 'ItemEditPage',
        component: () => import('@/views/Items/ItemEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Material :itemNumber bearbeiten',
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/material/stammdaten/neu',
        name: 'ItemCreatePage',
        component: () => import('@/views/Items/ItemEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Material erstellen',
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      /**
       * Berichte
       */
      {
        path: '/berichte/beispielbilder',
        name: 'ReportTemplateImagesPage',
        component: () => import('@/views/Report/ReportTemplateImages'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Report Template Images',
          resourceType: ResourceType.REPORT_TEMPLATE_IMAGE,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/berichte/beispielbilder/:reportTemplateImageNumber/bearbeiten',
        name: 'ReportTemplateImageEditPage',
        component: () => import('@/views/Report/ReportTemplateImageEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Report Template Image Edit',
          resourceType: ResourceType.REPORT_TEMPLATE_IMAGE,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      /**
       * Batterien
       */
      {
        path: '/batterien',
        name: 'BatteriesPage',
        component: () => import('@/views/Batteries/Batteries'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Batterien',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/batterien/:batteryNumber/bearbeiten',
        name: 'BatteryEditPage',
        component: () => import('@/views/Batteries/BatteryEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Batterien :batteryNumber bearbeiten',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/batterien/neu',
        name: 'BatteryCreatePage',
        component: () => import('@/views/Batteries/BatteryEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Batterien erstellen',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Wechselrichter
       */
      {
        path: '/wechselrichter',
        name: 'InvertersPage',
        component: () => import('@/views/Inverters/Inverters'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wechselrichter',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/wechselrichter/:inverterNumber/bearbeiten',
        name: 'InverterEditPage',
        component: () => import('@/views/Inverters/InverterEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wechselrichter :inverterNumber bearbeiten',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/wechselrichter/neu',
        name: 'InverterCreatePage',
        component: () => import('@/views/Inverters/InverterEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wechselrichter :inverterNumber erstellen',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Wallboxen
       */
      {
        path: '/wallboxen',
        name: 'WallboxesPage',
        component: () => import('@/views/Wallboxes/Wallboxes'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wallboxen',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/wallboxen/:wallboxNumber/bearbeiten',
        name: 'WallboxEditPage',
        component: () => import('@/views/Wallboxes/WallboxEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wallbox :wallboxNumber bearbeiten',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/wallboxen/neu',
        name: 'WallboxCreatePage',
        component: () => import('@/views/Wallboxes/WallboxEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wallbox erstellen',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Solar Panels
       */
      {
        path: '/module',
        name: 'SolarPanelsPage',
        component: () => import('@/views/SolarPanels/SolarPanels'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Module',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/module/:solarPanelNumber/bearbeiten',
        name: 'SolarPanelEditPage',
        component: () => import('@/views/SolarPanels/SolarPanelEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Module :solarPanelNumber bearbeiten',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/module/neu',
        name: 'SolarPanelCreatePage',
        component: () => import('@/views/SolarPanels/SolarPanelEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Module erstellen',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Energieflussrichtungssensoren
       */
      {
        path: '/energieflussrichtungssensoren',
        name: 'EnergyFlowDirectionSensorsPage',
        component: () => import('@/views/EnergyFlowDirectionSensors/EnergyFlowDirectionSensors'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Energieflussrichtungssensoren',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/energieflussrichtungssensoren/:energyFlowDirectionSensorNumber/bearbeiten',
        name: 'EnergyFlowDirectionSensorEditPage',
        component: () => import('@/views/EnergyFlowDirectionSensors/EnergyFlowDirectionSensorEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Sensoren :energyFlowDirectionSensorNumber bearbeiten',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/energieflussrichtungssensoren/neu',
        name: 'EnergyFlowDirectionSensorCreatePage',
        component: () => import('@/views/EnergyFlowDirectionSensors/EnergyFlowDirectionSensorEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Energieflussrichtungssensoren erstellen',
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * EVU's
       */
      {
        path: '/evu',
        name: 'PowerCompaniesPage',
        component: () => import('@/views/PowerCompanies/PowerCompanies'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'EVU',
          resourceType: ResourceType.POWER_COMPANY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/evu/:powerCompanyNumber/bearbeiten',
        name: 'PowerCompanyEditPage',
        component: () => import('@/views/PowerCompanies/PowerCompanyEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'EVU :powerCompanyNumber bearbeiten',
          resourceType: ResourceType.POWER_COMPANY,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/evu/neu',
        name: 'PowerCompanyCreatePage',
        component: () => import('@/views/PowerCompanies/PowerCompanyEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'EVU erstellen',
          resourceType: ResourceType.POWER_COMPANY,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Dokumente
       */
      {
        path: '/dokumente',
        name: 'DocumentsPage',
        component: () => import('@/views/Documents/Documents'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Dokumente',
          resourceType: ResourceType.DOCUMENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/dokumente/:documentNumber/bearbeiten',
        name: 'DocumentEditPage',
        component: () => import('@/views/Documents/DocumentEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Dokumente :documentNumber bearbeiten',
          resourceType: ResourceType.DOCUMENT,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/dokumente/neu',
        name: 'DocumentCreatePage',
        component: () => import('@/views/Documents/DocumentEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Dokumente erstellen',
          resourceType: ResourceType.DOCUMENT,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Angebote
       */
      {
        path: '/angebote',
        name: 'OffersPage',
        component: () => import('@/views/Offers/Offers'),
        meta: {
          requiredRole: 'ADMIN',
          title: ' Angebote',
          resourceType: ResourceType.OFFER,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/angebote/:offerNumber/bearbeiten',
        name: 'OfferEditPage',
        component: () => import('@/views/Offers/OfferEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Angebot :offerNumber bearbeiten',
          resourceType: ResourceType.OFFER,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/angebote/neu',
        name: 'OfferCreatePage',
        component: () => import('@/views/Offers/OfferEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Angebot erstellen',
          resourceType: ResourceType.OFFER,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Auftraggeber
       */
      {
        path: '/auftraggeber',
        name: 'EmployersPage',
        component: () => import('@/views/Employers/Employers'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Auftraggeber',
          resourceType: ResourceType.EMPLOYER,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/auftraggeber/:employerNumber/bearbeiten',
        name: 'EmployerEditPage',
        component: () => import('@/views/Employers/EmployerEdit'),
        props: true,
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Auftraggeber :employerNumber bearbeiten',
          resourceType: ResourceType.EMPLOYER,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/auftraggeber/neu',
        name: 'EmployerCreatePage',
        component: () => import('@/views/Employers/EmployerEdit'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Auftraggeber erstellen',
          resourceType: ResourceType.EMPLOYER,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Mandant
       */
      {
        path: '/mandanten',
        name: 'ClientsPage',
        component: () => import('@/views/Clients/Clients'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mandanten',
          resourceType: ResourceType.CLIENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/mandanten/:clientNumber/bearbeiten',
        name: 'ClientEditPage',
        component: () => import('@/views/Clients/ClientEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mandaten :clientNumber bearbeiten',
          resourceType: ResourceType.CLIENT,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/mandanten/neu',
        name: 'ClientCreatePage',
        component: () => import('@/views/Clients/ClientEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mandaten erstellen',
          resourceType: ResourceType.CLIENT,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Mitarbeiter
       */
      {
        path: '/mitarbeiter',
        name: 'EmployeesPage',
        component: () => import('@/views/Employees/Employees'),
        meta: {
          title: 'Mitarbeiter',
          resourceType: ResourceType.EMPLOYEE,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/mitarbeiter/:employeeNumber/bearbeiten',
        name: 'EmployeeEditPage',
        component: () => import('@/views/Employees/EmployeeEdit'),
        props: true,
        meta: {
          title: 'Mitarbeiter :employeeNumber bearbeiten',
          resourceType: ResourceType.EMPLOYEE,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/mitarbeiter/neu',
        name: 'EmployeeCreatePage',
        component: () => import('@/views/Employees/EmployeeEdit'),
        meta: {
          title: 'Mitarbeiter erstellen',
          resourceType: ResourceType.EMPLOYEE,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Elektrofachkraft
       */
      {
        path: '/elektrofachkraft',
        name: 'ElectriciansPage',
        component: () => import('@/views/Electricians/Electricians'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Elektrofachkraft',
          resourceType: ResourceType.ELECTRICIAN,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/elektrofachkraft/:electricianNumber/bearbeiten',
        name: 'ElectricianEditPage',
        component: () => import('@/views/Electricians/ElectricianEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Elektrofachkraft :electricianNumber bearbeiten',
          resourceType: ResourceType.ELECTRICIAN,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/elektrofachkraft/neu',
        name: 'ElectricianCreatePage',
        component: () => import('@/views/Electricians/ElectricianEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Elektrofachkraft erstellen',
          resourceType: ResourceType.ELECTRICIAN,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Messstellenbetreiber
       */
      {
        path: '/messstellenbetreiber',
        name: 'MeasurementPointOperatorsPage',
        component: () => import('@/views/MeasurementPointOperators/MeasurementPointOperators'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Messstellenbetreiber',
          resourceType: ResourceType.MEASUREMENT_POINT_OPERATOR,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/messstellenbetreiber/:measurementPointOperatorNumber/bearbeiten',
        name: 'MeasurementPointOperatorEditPage',
        component: () => import('@/views/MeasurementPointOperators/MeasurementPointOperatorEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Messstellenbetreiber :measurementPointOperatorNumber bearbeiten',
          resourceType: ResourceType.MEASUREMENT_POINT_OPERATOR,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/zugangskontrolle',
        name: 'PolicyEditPage',
        component: () => import('@/views/Policies/PolicyEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          resourceType: ResourceType.POLICY,
          requiredActionPermissions: [AuthorizableActions.LIST, AuthorizableActions.READ],
          title: 'Zugangskontrolle',
        },
      },
      {
        path: '/messstellenbetreiber/neu',
        name: 'MeasurementPointOperatorCreatePage',
        component: () => import('@/views/MeasurementPointOperators/MeasurementPointOperatorEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Messtellenbetreiber erstellen',
          resourceType: ResourceType.MEASUREMENT_POINT_OPERATOR,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      /**
       * Users
       */
      {
        path: '/benutzer',
        name: 'UsersPage',
        component: () => import('@/views/Users/Users'),
        meta: {
          requiredRole: 'ADMIN',
          resourceType: ResourceType.USER,
          requiredActionPermissions: [AuthorizableActions.LIST],
          breadcrumbs: [{ name: 'Home', link: 'home' }, { name: 'Benutzer' }],
          title: 'Benutzer',
        },
      },
      {
        path: '/benutzer/erstellen',
        name: 'UserCreatePage',
        component: () => import('@/views/Users/UserEdit'),
        meta: {
          breadcrumbs: [
            { name: 'Home', link: 'home' },
            { name: 'Benutzer', link: 'UsersPage' },
            { name: 'Benutzer erstellen' },
          ],
          title: 'Benutzer erstellen',
          resourceType: ResourceType.USER,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },
      {
        path: '/benutzer/:userNumber/bearbeiten',
        name: 'UserEditPage',
        component: () => import('@/views/Users/UserEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Benutzer bearbeiten',
          resourceType: ResourceType.USER,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      /**
       * Changelog
       */
      {
        path: '/updates',
        name: 'ChangelogPage',
        component: () => import(/* webpackChunkName: "about" */ '../views/Changelog.vue'),
        meta: {
          resourceType: ResourceType.UPDATE,
          requiredActionPermissions: [AuthorizableActions.READ],
        },
      },
      /**
       * Terminplanung
       */
      {
        path: '/planungskalender',
        name: 'AppointmentsPage',
        component: () => import('@/views/Appointments/Appointments'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Planungskalender',
          resourceType: ResourceType.APPOINTMENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/kalender',
        name: 'AppointmentsCalendarPage',
        component: () => import('@/views/Appointments/AppointmentsCalendar'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Kalender',
          resourceType: ResourceType.APPOINTMENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/terminkapazitaet',
        name: 'AppointmentCapacitiesPage',
        component: () => import('@/views/Appointments/AppointmentCapacities'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Terminkapazitaet',
          resourceType: ResourceType.APPOINTMENT_CAPACITY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      /**
       * Aufgaben
       */
      {
        path: '/aufgaben',
        name: 'TasksPage',
        component: () => import('@/views/Tasks/Tasks'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Aufgaben',
          resourceType: ResourceType.TASK,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      /**
       * Bewerber
       */
      {
        path: '/bewerber',
        name: 'ApplicantsPage',
        component: () => import('@/views/Applicants/Applicants'),
        meta: {
          title: 'Bewerber',
          resourceType: ResourceType.APPLICANT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      /**
       * Dashboard Termine
       */
      {
        path: '/dashboard',
        name: 'DashboardPage',
        component: () => import('@/views/Dashboards/Dashboard'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'MANAGER'],
          title: 'Dashboard',
          resourceType: ResourceType.PROJECT,
          requiredActionPermissions: [AuthorizableActions.LIST, AuthorizableActions.READ],
        },
      },
      /**
       * AdminTimeTracking Dev
       */
      {
        path: '/zeiterfassung',
        name: 'AdminTimeTrackingPage',
        component: () => import('@/views/TimeTracking/AdminTimeTracking'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Zeiterfassung',
          resourceType: ResourceType.TIME_TRACKING,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      /**
       * AdminSales Dev
       */
      {
        path: '/sales/overview',
        name: 'AdminSalesPage',
        component: () => import('@/views/Sales/AdminSales'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Sales',
          resourceType: ResourceType.SALES,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      /**
       * Academy Dev
       */
      {
        path: '/academy',
        name: 'AcademyPage',
        component: () => import('@/views/Academy/AcademyPage'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'STUDENT'],
          title: 'Academy',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.READ],
        },
      },
      {
        path: '/academy/stundenplan',
        name: 'AcademyCalendar',
        component: () => import('@/views/Academy/AcademyCalendar'),
        meta: {
          title: 'Stundenplan',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/academy/lehrplan',
        name: 'Timetable',
        component: () => import('@/views/Academy/Timetable'),
        meta: {
          title: 'Stundenplantermine',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },

      {
        path: '/academy/lehrmaterial',
        name: 'Contents',
        component: () => import('@/views/Academy/Contents'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'STUDENT'],
          title: 'Lehrinhalte',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },

      {
        path: '/academy/klassen',
        name: 'Sessions',
        component: () => import('@/views/Academy/Sessions'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'STUDENT'],
          title: 'Klassen',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/academy/schueler',
        name: 'Students',
        component: () => import('@/views/Academy/Students'),
        meta: {
          title: 'Schüler',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },

      {
        path: '/academy/schueler/neu',
        name: 'StudentCreatePage',
        component: () => import('@/views/Academy/StudentEdit'),
        props: true,
        meta: {
          title: 'Schüler erstellen',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      {
        path: '/academy/schueler/:studentNumber/bearbeiten',
        name: 'StudentEditPage',
        component: () => import('@/views/Academy/StudentEdit'),
        props: true,
        meta: {
          title: 'Schüler :studentNumber bearbeiten',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/academy/trainer',
        name: 'Trainers',
        component: () => import('@/views/Academy/Trainers'),
        meta: {
          title: 'Trainer',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },

      {
        path: '/academy/trainer/neu',
        name: 'TrainerCreatePage',
        component: () => import('@/views/Academy/TrainerEdit'),
        props: true,
        meta: {
          title: 'Trainer erstellen',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      {
        path: '/academy/trainer/:trainerNumber/bearbeiten',
        name: 'TrainerEditPage',
        component: () => import('@/views/Academy/TrainerEdit'),
        props: true,
        meta: {
          title: 'Trainer :trainerNumber bearbeiten',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/academy/kurse',
        name: 'Courses',
        component: () => import('@/views/Academy/Courses'),
        meta: {
          title: 'Kurse',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },

      {
        path: '/academy/kurs/neu',
        name: 'CourseCreatePage',
        component: () => import('@/views/Academy/CourseEdit'),
        props: true,
        meta: {
          title: 'Kurs erstellen',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      {
        path: '/academy/kurs/:courseNumber/bearbeiten',
        name: 'CourseEditPage',
        component: () => import('@/views/Academy/CourseEdit'),
        props: true,
        meta: {
          title: 'Kurs :courseNumber bearbeiten',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },

      {
        path: '/academy/klassen/neu',
        name: 'SessionCreatePage',
        component: () => import('@/views/Academy/SessionEdit'),

        meta: {
          title: 'Klasse erstellen',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      {
        path: '/academy/klassen/:sessionNumber/bearbeiten',
        name: 'SessionEditPage',
        component: () => import('@/views/Academy/SessionEdit'),
        props: true,
        meta: {
          title: 'Klasse :sessionNumber bearbeiten',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      {
        path: '/academy/lehrmaterial/neu',
        name: 'ContentCreatePage',
        component: () => import('@/views/Academy/ContentEdit'),
        meta: {
          title: 'Lehrmaterial erstellen',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.CREATE],
        },
      },

      {
        path: '/academy/lehrmaterial/:contentNumber/bearbeiten',
        name: 'ContentEditPage',
        component: () => import('@/views/Academy/ContentEdit'),
        props: true,
        meta: {
          title: 'Lehrmaterial :contentNumber bearbeiten',
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },

      /**
       * sales
       */
      {
        path: '/sales/cockpit',
        name: 'SalesCockpitPage',
        component: () => import('@/views/Sales/SalesCockpit'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Sales Cockpit',
          resourceType: ResourceType.SALES,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
      {
        path: '/sales/:projectNumber/bearbeiten',
        name: 'SalesEditPage',
        component: () => import('@/views/Sales/SalesEdit'),
        props: true,
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Kunde :projectNumber bearbeiten',
          resourceType: ResourceType.SALES,
          requiredActionPermissions: [AuthorizableActions.READ, AuthorizableActions.UPDATE],
        },
      },
      /**
       * Aktivitätsprotokoll
       */
      {
        path: '/aktivitaetsprotokoll',
        name: 'AuditsPage',
        component: () => import('@/views/Audit/Audits'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Aktivitätsprotokoll',
          resourceType: ResourceType.AUDIT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Store.dispatch('fadeFlashMessage');
  // Updating the document title
  let pageTitle = to.meta.title;
  if (pageTitle) {
    if (to.params.projectNumber) {
      pageTitle = pageTitle.replace(':projectNumber', to.params.projectNumber);
    }
    if (to.params.employeeNumber) {
      pageTitle = pageTitle.replace(':employeeNumber', to.params.employeeNumber);
    }
    document.title = pageTitle;
  }

  // Login routes don't need policy checks
  if (to.name === 'Login') {
    return next();
  }

  Store.dispatch('auth/loginFromStorage').then(
    (response) => {
      const user = Store.getters['auth/getCurrentUser'];

      // Check if user is logged in
      if (!user) {
        next('/login');
      }
      // Check if the route requires authentication
      else if (
        to.matched.some((record) => record.meta.requiresAuthentication) &&
        !isAuthenticated(getAuthToken())
      ) {
        next('/login');
      } else {
        // Make sure user policies are loaded before checking permissions
        const userPolicies = Store.getters['policies/getUserPolicies'];

        // If we don't have policies yet and need them for this route, load them first
        const needsPolicies = to.matched.some(
          (record) => record.meta.resourceType && record.meta.requiredActionPermissions
        );

        if (needsPolicies && (!userPolicies || userPolicies.length === 0)) {
          // Load policies and then retry the navigation
          Store.dispatch('policies/fetchUserPolicies')
            .then(() => {
              // Rerun the navigation with the same destination after policies are loaded
              router.push(to.fullPath);
            })
            .catch((error) => {
              console.error('Error loading user policies:', error);
              next({ name: '403' });
            });
          return;
        }

        // Continue with normal authorization flow since policies are loaded
        // Determine which authorization check to use
        const hasResourcePermissionCheck = to.matched.some(
          (record) => record.meta.resourceType && record.meta.requiredActionPermissions
        );
        const hasRoleCheck = to.matched.some((record) => record.meta.requiredRole);
        const hasAuthorizedRolesCheck = to.matched.some((record) => record.meta.authorizedRoles);

        // Priority 1: Check resource-based permissions if defined
        if (hasResourcePermissionCheck) {
          const resourcePermissionResult = to.matched.every(
            (record) =>
              !record.meta.resourceType ||
              !record.meta.requiredActionPermissions ||
              hasResourcePermission(
                user,
                record.meta.resourceType,
                record.meta.requiredActionPermissions
              )
          );

          if (!resourcePermissionResult) {
            console.log('Policy permission check failed');
            next({ name: '403' });
            return;
          }
        }
        // Priority 2: If no resource permissions or they succeeded, check role-based permissions
        else if (hasRoleCheck) {
          if (!hasRole(user, to.meta.requiredRole)) {
            console.log('Role permission check failed');
            next({ name: '403' });
            return;
          }
        }
        // Priority 3: If no resource or role permissions or they succeeded, check authorized roles
        else if (hasAuthorizedRolesCheck) {
          if (!authorize(user, to.meta.authorizedRoles, to.params.slug)) {
            console.log('Authorize check failed', user);
            next({ name: '403' });
            return;
          }
        }

        // All permission checks have been passed or were not required
        // Always update user information to get new notifications
        Store.dispatch('auth/refreshUser');
        next();
      }
    },
    (error) => {
      // console.log(error)
      next({ name: '403' });
    }
  );
});

export default router;

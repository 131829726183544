<template>
  <ul class="nav">
    <NavLink
      v-for="item in filteredNavigationItems"
      :key="item.index"
      :activeItem="activeItem"
      :header="item.header"
      :link="item.link"
      :iconName="item.iconName"
      :index="item.index"
      :isHeader="item.isHeader"
      :resourceType="item.resourceType"
      :requiredActionPermissions="item.requiredActionPermissions"
      :childrenLinks="item.childrenLinks"
      @submenu-clicked="handleSubmenuClick"
    />
  </ul>
</template>

<script>
import NavLink from './NavLink/NavLink';
import { mapGetters } from 'vuex';
import { ResourceType, AuthorizableActions } from '@/helpers/enums.js';
import { hasResourcePermission } from '@/helpers/userCapabilities.js';

export default {
  name: 'AdminNavigation',
  components: { NavLink },
  props: ['activeItem'],
  methods: {
    handleSubmenuClick(childIndex) {
      // This method is needed to capture submenu clicks at the root level
      console.log(`Root level received submenu click: ${childIndex}`);
    },
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters(['getUser']),
    ...mapGetters('policies', ['getUserPolicies']),
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
    isAdmin() {
      return this.getCurrentUser.role === 'ADMIN';
    },
    userPolicies() {
      return this.getUserPolicies;
    },
    filteredNavigationItems() {
      const items = [
        {
          header: 'Dashboard',
          link: '/dashboard',
          iconName: 'flaticon-home',
          index: 'dashboard',
          isHeader: true,
          resourceType: ResourceType.PROJECT,
          requiredActionPermissions: [AuthorizableActions.LIST, AuthorizableActions.READ],
        },
        {
          header: 'Projekte',
          link: '/projekte',
          iconName: 'flaticon-star',
          index: 'projects',
          isHeader: true,
          resourceType: ResourceType.PROJECT,
          requiredActionPermissions: [AuthorizableActions.LIST, AuthorizableActions.READ],
        },
        {
          header: 'Terminplanung',
          link: '/planungskalender',
          iconName: 'flaticon-calendar',
          index: 'appointment-planning',
          isHeader: true,
          resourceType: ResourceType.APPOINTMENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Termin Slots',
          link: '/terminkapazitaet',
          iconName: 'flaticon-cloud',
          index: 'appointment-slots',
          isHeader: true,
          resourceType: ResourceType.APPOINTMENT_CAPACITY,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Aufgaben',
          link: '/aufgaben',
          iconName: 'flaticon-umbrella',
          index: 'tasks',
          isHeader: true,
          resourceType: ResourceType.TASK,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Kalender',
          link: '/kalender',
          iconName: 'flaticon-calendar',
          index: 'calendar',
          isHeader: true,
          resourceType: ResourceType.APPOINTMENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Sales',
          link: '/sales/',
          iconName: 'flaticon-binoculars',
          index: 'sales',
          isHeader: true,
          resourceType: ResourceType.SALES,
          requiredActionPermissions: [AuthorizableActions.LIST],
          childrenLinks: [
            {
              header: 'Overview',
              link: '/sales/overview',
              index: 'sales-overview',
              resourceType: ResourceType.SALES,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
          ],
        },
        {
          header: 'Personalverwaltung',
          link: '/personalverwaltung',
          iconName: 'flaticon-users',
          index: 'hr',
          isHeader: true,
          resourceType: ResourceType.HUMAN_RESOURCE,
          requiredActionPermissions: [AuthorizableActions.LIST],
          childrenLinks: [
            {
              header: 'Benutzer',
              link: '/benutzer',
              index: 'users',
              resourceType: ResourceType.HUMAN_RESOURCE,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Mitarbeiter',
              link: '/mitarbeiter',
              index: 'employees',
              resourceType: ResourceType.HUMAN_RESOURCE,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Mandanten',
              link: '/mandanten',
              index: 'tenants',
              resourceType: ResourceType.CLIENT,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Auftraggeber',
              link: '/auftraggeber',
              index: 'clients',
              resourceType: ResourceType.EMPLOYER,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'EVU',
              link: '/evu',
              index: 'evu',
              resourceType: ResourceType.POWER_COMPANY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Elektrofachkräfte',
              link: '/elektrofachkraft',
              index: 'electricians',
              resourceType: ResourceType.ELECTRICIAN,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Messstellenbetreiber',
              link: '/messstellenbetreiber',
              index: 'meter-operators',
              resourceType: ResourceType.MEASUREMENT_POINT_OPERATOR,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Zugangskontrolle',
              link: '/zugangskontrolle',
              index: 'access-control',
              resourceType: ResourceType.POLICY,
              requiredActionPermissions: [AuthorizableActions.LIST, AuthorizableActions.READ],
            },
          ],
        },
        {
          header: 'Anlagen',
          link: '/anlagen',
          iconName: 'flaticon-power',
          index: 'installations',
          isHeader: true,
          resourceType: ResourceType.SYSTEM,
          requiredActionPermissions: [AuthorizableActions.LIST],
          childrenLinks: [
            {
              header: 'Batteriespeicher',
              link: '/batterien',
              index: 'batteries',
              resourceType: ResourceType.SYSTEM,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Wechselrichter',
              link: '/wechselrichter',
              index: 'inverters',
              resourceType: ResourceType.SYSTEM,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Module',
              link: '/module',
              index: 'modules',
              resourceType: ResourceType.SYSTEM,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'EnFluRi',
              link: '/energieflussrichtungssensoren',
              index: 'energy-flow-sensors',
              resourceType: ResourceType.SYSTEM,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Wallboxen',
              link: '/wallboxen',
              index: 'wallboxes',
              resourceType: ResourceType.SYSTEM,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
          ],
        },
        {
          header: 'Material',
          link: '/material',
          iconName: 'flaticon-price-tag',
          index: 'material',
          isHeader: true,
          resourceType: ResourceType.MATERIAL,
          requiredActionPermissions: [AuthorizableActions.LIST],
          childrenLinks: [
            {
              header: 'Material Stammdaten',
              link: '/material/stammdaten',
              index: 'material-master',
              resourceType: ResourceType.MATERIAL,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Lager',
              link: '/material/lager',
              index: 'inventory',
              resourceType: ResourceType.MATERIAL,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
          ],
        },
        {
          header: 'Berichte',
          link: '/berichte',
          iconName: 'flaticon-diploma',
          index: 'reports',
          isHeader: true,
          resourceType: ResourceType.REPORT,
          requiredActionPermissions: [AuthorizableActions.LIST],
          childrenLinks: [
            {
              header: 'Beispielbilder',
              link: '/berichte/beispielbilder',
              index: 'report-examples',
              resourceType: ResourceType.REPORT_TEMPLATE_IMAGE,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
          ],
        },
        {
          header: 'Editor',
          link: '/dokumente',
          iconName: 'flaticon-document',
          index: 'editor',
          isHeader: true,
          resourceType: ResourceType.DOCUMENT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Angebote',
          link: '/angebote',
          iconName: 'flaticon-note',
          index: 'offers',
          isHeader: true,
          resourceType: ResourceType.OFFER,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Academy',
          link: '/academy',
          iconName: 'flaticon-diploma',
          index: 'academy',
          isHeader: true,
          resourceType: ResourceType.ACADEMY,
          requiredActionPermissions: [AuthorizableActions.LIST],
          childrenLinks: [
            {
              header: 'Course',
              link: '/academy/kurse',
              index: 'courses',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Schüler',
              link: '/academy/schueler',
              index: 'students',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Trainer',
              link: '/academy/trainer',
              index: 'trainers',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Academy',
              link: '/academy',
              index: 'academy-home',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.READ],
            },
            {
              header: 'Stundenplantermine',
              link: '/academy/lehrplan',
              index: 'academy-schedule',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Calendar',
              link: '/academy/stundenplan',
              index: 'academy-calendar',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Lehrinhalte',
              link: '/academy/lehrmaterial',
              index: 'teaching-materials',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
            {
              header: 'Klasseninhalte',
              link: '/academy/klassen',
              index: 'class-contents',
              resourceType: ResourceType.ACADEMY,
              requiredActionPermissions: [AuthorizableActions.LIST],
            },
          ],
        },
        {
          header: 'Bewerber',
          link: '/bewerber',
          iconName: 'flaticon-radar',
          index: 'applicants',
          isHeader: true,
          resourceType: ResourceType.APPLICANT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Zeiterfassung',
          link: '/zeiterfassung/',
          iconName: 'flaticon-binoculars',
          index: 'time-tracking',
          isHeader: true,
          resourceType: ResourceType.TIME_TRACKING,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Aktivitätsprotokoll',
          link: '/aktivitaetsprotokoll',
          iconName: 'flaticon-note',
          index: 'activity-log',
          isHeader: true,
          resourceType: ResourceType.AUDIT,
          requiredActionPermissions: [AuthorizableActions.LIST],
        },
        {
          header: 'Updates',
          link: '/updates',
          iconName: 'flaticon-idea',
          index: 'updates',
          isHeader: true,
          resourceType: ResourceType.UPDATE,
          requiredActionPermissions: [AuthorizableActions.READ],
        },
      ];

      // console.log('User:', this.getCurrentUser);
      // console.log('Policies:', this.userPolicies);

      return items.filter((item) => {
        // Admin sees everything
        if (this.isAdmin) return true;

        // Check if user has access to this item based on policies
        return hasResourcePermission(
          this.getCurrentUser,
          item.resourceType,
          item.requiredActionPermissions
        );
      });
    },
  },
  created() {},
};
</script>

<style>
.sub-menu .router-link-active .icon {
  display: none;
}
/* Webkit browsers (Chrome, Safari, newer versions of Opera and Edge) */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #98269f;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: #98269f #f1f1f1;
}
</style>

import gql from 'graphql-tag';

/**
 * Defines the fields which are requested for an project
 * used for all queries/mutations which return an project
 * @type {DocumentNode}
 */
export const PROJECT_REQUEST_FIELDS = gql`
  fragment projectFields on Project {
    id
    number
    clientProjectNumber
    nextProjectNumber
    previousProjectNumber
    employerProjectNumber
    powerCompanyProjectNumber
    powerCompanyProjectNumber2
    powerCompanyProjectNumber3
    powerCompanyClearanceAt
    installationAddressState
    employerClearanceAt
    createdAt
    updatedAt
    deliveryAt
    consumablesDeliveredAt
    invoiceSentToEmployerAt
    employerPayedAt
    installationTeamPayedAt
    initialInformationSentToCustomerAt
    missingDocumentsSentToCustomerAt
    documentationSentToEmployerAt
    appointmentConfirmationSentAt
    projectState
    projectTypeState
    askEmployerCategoryState
    projectDescriptionState
    mandateState
    layoutPlanState
    installationImagesState
    installationTeamDocumentationState
    netRegistrationRequirementsFullfilledState
    employerInvoiceNumber
    technicalReviewDescription
    powerCompanyDescription
    customerDescription
    netRegistrationAttachmentsBuildState
    operationRegistrationAttachmentsBuildState
    overviewSchematicAttachmentsBuildState
    reportAttachmentsBuildState
    accountingDescription
    portalRegistrationState
    consumablesOrderedState
    consumablesInStockState
    substructureVerificationState
    dcVerificationState
    acVerificationState
    scaffoldingVerificationState
    measurementVerificationState
    measurementApprovementVerificationState
    serviceVerificationState
    serviceApprovementVerificationState
    serviceApprovementNote
    acceptanceApprovementVerificationState
    acceptanceApprovementNote
    invoiceApprovementVerificationState
    invoiceApprovementNote
    askEmployerNote
    answerFromEmployerNote
    accountingAdditionalAcPositions
    newestInstallationAppointment {
      installationStartAt
      employees {
        number
        firstname
        lastname
      }
    }
    posts {
      id
      number
      content
      createdAt
      updatedAt
      user {
        id
        number
        firstname
        lastname
        email
        avatar {
          path
        }
      }
    }
    technicalReview {
      dcInspectedState
      dcNotes
      acInspectedState
      acNotes
      additionalOrders
      additionalOrdersDispatchedState
      additionalOrdersDispatchedAt
      installationTeamNotes
      preliminaryExaminationSuccessfulState
      onSiteAppointmentProtocolUploadedToVlinkState
      preferredDeliveryInVlinkAt
      appointmentNotes
      dcTimeExpenditure
      acTimeExpenditure
    }
    vlink {
      projectNr
      projectId
      token
      url
      vlinkConnectedState
      updatedAt
      createdAt
    }
    deferredState {
      _id
      deferred
      reason
      description
      comment
      createdAt
      updatedAt
    }
    onHoldState {
      _id
      onHold
      reason
      description
      comment
      createdAt
      updatedAt
    }
    bricksPlasteredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cardboardPoppetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    sales {
      phase
      state
      package
      inCharge {
        number
        firstname
        lastname
        id
      }
      usage
      kwhprice
      callState
      notes
      notesAssembly
      notesAnalysis
      householdMembers
      electricMeter
      yearlyExpense
    }
    pedimentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pitchedRoofOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    rafterVisibleAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    rafterOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    outerRoofOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    bricksFrontAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    bricksBackAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    bricksOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofSidesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofSidesShadowsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofSidesSketchAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofSidesOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofAccessAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofAccessOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    hakAndZsSameRoomAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    hakAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    hakOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    zsWithCoverAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    zsWithoutCoverAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    zsOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    mainElectricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    seconaryElectricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    acInstallationLocationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    locationAndCablePathOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    equipotentialBusBarPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    earthingPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    earthingOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    internetConnectionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    heavyCurrentPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    wallboxLocationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    wallboxOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRoofSurfaceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRoofOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceReport {
      completed {
        type
        value
      }
      brickAndUkAssemblyType
      brickAndUkA2State
      brickAndUkBState
      railsEarthed
      brickAndUkOptionalNotes
      cableManagementC2State
      cableManagementOptionalNotes
      hakLocationState
      hakLockedState
      hakToZsDistance
      hakAndZsSameRoomState
      zsLocationState
      additionalSubDistributionState
      electricMeterCount
      electricMeterList
      intermediateMeterRequestedState
      meterCombinationRequestedState
      disassembledMetersOnSiteState
      meterCascadeRequestedState
      installationLocationWithInverterAndMateboxState
      distanceMeasurement
      stringPlanAvailableState
      cablePathDistance
      cableSelection
      equipotentialBusBarPresentState
      secondaryEquipotentialBusBarPresentState
      potentialGroundRodPresentState
      earthingSupply16mmState
      internetConnectionTypeState
      lanConnectionPresentState
      qcellsWallboxContractState
      briefingAndDocumentationState
      solarPaneldOptionalNotes
      serviceOptionalNotes
    }
    qualityReport {
      completed {
        type
        value
      }
      isRoofAccessibleFromInsideState
      isRoofAccessibleFromInsideNote
      properSleeveUsedAtRoofPenetrationState
      underlaySealedWellAtCableEntryState
      underlayDamagedOrWaterStainsState
      labelingOfStringLinesState
      pvStringCableCorrectCrossSectionState
      peCableCorrectlySizedState
      cablesProperlySecuredState
      crimpingProfessionallyDoneState
      correctSelectionOfPvConnectorsOnStringCableState
      allPlugConnectionsProfessionallyMadeState
      cablesNeatlyLaidInsideBuildingState
      spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState
      surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState
      properSleeveUsedAtRoofPenetrationNote
      underlaySealedWellAtCableEntryNote
      underlayDamagedOrWaterStainsNote
      labelingOfStringLinesNote
      pvStringCableCorrectCrossSectionNote
      peCableCorrectlySizedNote
      cablesProperlySecuredNote
      crimpingProfessionallyDoneNote
      correctSelectionOfPvConnectorsOnStringCableNote
      allPlugConnectionsProfessionallyMadeNote
      cablesNeatlyLaidInsideBuildingNote
      spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterNote
      surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryNote
      documentationProjectReportAvailableState
      specialCustomerRequestsDocumentedState
      tileTypeAndWidthDocumentedState
      replacementTileInstalledState
      lightningProtectionPresentState
      documentationProjectReportAvailableNote
      specialCustomerRequestsDocumentedNote
      tileTypeAndWidthDocumentedNote
      replacementTileInstalledNote
      lightningProtectionPresentNote
      substructureTensionFreeCheckedState
      substructureInstalledAsPerReportState
      eachRailSecuredWithMinTwoHooksState
      distanceBetweenHooksMoreThan140cmState
      correctRoofHooksUsedState
      distanceRoofHookToTile35mmState
      minScrewDistanceToRafterEdge24mmState
      roofHookProperlyFixedToRafterState
      overhangObservedState
      moduleFieldProperlyGroundedState
      substructureTensionFreeCheckedNote
      substructureInstalledAsPerReportNote
      eachRailSecuredWithMinTwoHooksNote
      distanceBetweenHooksMoreThan140cmNote
      correctRoofHooksUsedNote
      distanceRoofHookToTile35mmNote
      minScrewDistanceToRafterEdge24mmNote
      roofHookProperlyFixedToRafterNote
      overhangObservedNote
      moduleFieldProperlyGroundedNote
      approvedAuxiliaryRafterUsedState
      approvedAuxiliaryRafterUsedNote
      rafterWidthMeasuredAndComparedToDocsState
      rafterWidthMeasuredAndComparedToDocsNote
      noOpenOrDamagedTilesState
      noOpenOrDamagedTilesNote
      noShiftedOrIncorrectlyLaidTilesState
      noShiftedOrIncorrectlyLaidTilesNote
      tilesProperlyCleanedState
      tilesProperlyCleanedNote
      correctPanHeadScrewsUsedState
      correctPanHeadScrewsUsedNote
      minRailOverhangAfterEndClamp20mmState
      minRailOverhangAfterEndClamp20mmNote
      distancesToRidgeEavesNotLessThan20cmState
      distancesToRidgeEavesNotLessThan20cmNote
      moduleClampAreasObservedState
      moduleClampAreasObservedNote
      modulesNoDamagesDirtShadingState
      modulesNoDamagesDirtShadingNote
      moduleClampsProperlyTightenedState
      moduleClampsProperlyTightenedNote
      thermalSeparationAfter12mModuleFieldState
      thermalSeparationAfter12mModuleFieldNote
      breakthroughProperlySealedState
      breakthroughProperlySealedNote
      distanceLightningProtectionToConductivePartsLessThan05mState
      distanceLightningProtectionToConductivePartsLessThan05mNote
      gutterUndamagedState
      gutterUndamagedNote
      notBuiltOverRoofOverhangState
      notBuiltOverRoofOverhangNote
      cableProperlyLaidInChimneyState
      cableProperlyLaidInChimneyNote
      distanceToDisturbingSurfaces10cmObservedState
      distanceToDisturbingSurfaces10cmObservedNote
      cableEntryWithReplacementPlateAndFlexedTileState
      cableEntryWithReplacementPlateAndFlexedTileNote
      cablesDoNotRestOnTilesState
      cablesDoNotRestOnTilesNote
      cablesLaidProtectedUnderTilesState
      cablesLaidProtectedUnderTilesNote
      cableEntryOnRoofProperlyDoneState
      cableEntryOnRoofProperlyDoneNote
      cableLaidThroughChimneyState
      cableLaidThroughChimneyNote
      cableLaidInProtectiveSleeveAgainstUvRadiationState
      cableLaidInProtectiveSleeveAgainstUvRadiationNote
      peCableProperlySecuredState
      peCableProperlySecuredNote
      documentationProjectReportPresentState
      documentationProjectReportPresentNote
      customerSpecialRequestsConsideredState
      customerSpecialRequestsConsideredNote
      flatRooflightningProtectionPresentState
      flatRooflightningProtectionPresentNote
      properGroundingFlatRoofSystemState
      properGroundingFlatRoofSystemNote
      ballastingAccordingToProjectReportState
      ballastingAccordingToProjectReportNote
      minimumModuleBearingAreaMaintainedState
      minimumModuleBearingAreaMaintainedNote
      qFlatProperlyConnectedState
      qFlatProperlyConnectedNote
      distanceToDisturbingSurfacesMaintainedState
      distanceToDisturbingSurfacesMaintainedNote
      flatRoofmoduleClampsProperlyTightenedState
      flatRoofmoduleClampsProperlyTightenedNote
      modulesNoDamageDirtShadingState
      modulesNoDamageDirtShadingNote
      typeOfCableProtectionOutdoorsState
      typeOfCableProtectionOutdoorsNote
      correctSelectionOfPvConnectorsState
      correctSelectionOfPvConnectorsNote
      cablesDoNotRestOnRoofState
      cablesDoNotRestOnRoofNote
      cableEntryDoneProperlyState
      cableEntryDoneProperlyNote
      cablesProtectedFromUvState
      cablesProtectedFromUvNote
      pvStringCableProperlySizedState
      pvStringCableProperlySizedNote
      stringCableLabelingState
      stringCableLabelingNote
      peCableProperlySizedState
      peCableProperlySizedNote
      flatRoofcablesProperlySecuredState
      flatRoofcablesProperlySecuredNote
      crimpingProperlyDoneState
      crimpingProperlyDoneNote
      pvSystemLabeledInOrAtDistributionBoardState
      pvSystemLabeledInOrAtDistributionBoardNote
      testReportAvailableWithAllMeasurementsState
      testReportAvailableWithAllMeasurementsNote
      rcdTypeB300maAvailableInMeterAreaState
      rcdTypeB300maAvailableInMeterAreaNote
      stringPlanFiledInMeterCabinetState
      stringPlanFiledInMeterCabinetNote
      safetyDistancesMaintainedState
      safetyDistancesMaintainedNote
      correctLocationForSystemState
      correctLocationForSystemNote
      correctLocationForWallboxState
      correctLocationForWallboxNote
      customerRequestsDocumentedState
      customerRequestsDocumentedNote
      slsInstalledState
      slsInstalledNote
      componentsDamagedState
      componentsDamagedNote
      crimpingDoneProperlyState
      crimpingDoneProperlyNote
      ussWiredProperlyInCabinetState
      ussWiredProperlyInCabinetNote
      ussBoxWiredProperlyState
      ussBoxWiredProperlyNote
      bendingRadiiMaintainedState
      bendingRadiiMaintainedNote
      correctWiringOfMateboxState
      correctWiringOfMateboxNote
      correctWiringOfBatteryState
      correctWiringOfBatteryNote
      allComponentsProperlyGroundedState
      allComponentsProperlyGroundedNote
      correctColorCodingAndLabelingState
      correctColorCodingAndLabelingNote
      correctWiringOfPvConnectorsState
      correctWiringOfPvConnectorsNote
      cableDuctProperlySizedAndCutState
      cableDuctProperlySizedAndCutNote
      nhHakSelectiveToSlsState
      nhHakSelectiveToSlsNote
      checkNetworkTypeState
      checkNetworkTypeNote
      earthRodConnectedToHpasState
      earthRodConnectedToHpasNote
      connectionBetweenHpasAndPasProperlyMadeState
      connectionBetweenHpasAndPasProperlyMadeNote
      insulationProperlyStrippedState
      insulationProperlyStrippedNote
      polarityCheckState
      polarityCheckNote
      stringOpenCircuitVoltageState
      stringOpenCircuitVoltageNote
      shortCircuitCurrentState
      shortCircuitCurrentNote
      insulationResistanceState
      insulationResistanceNote
      isConnectorSealingIntactState
      isConnectorSealingIntactNote
      isSurgeProtectionBoxPresenState
      isSurgeProtectionBoxPresentNote
      isSurgeProtectionAtHouseEntryState
      isSurgeProtectionAtHouseEntryNote
      isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState
      isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mNote
      isModuleFrameUndamagedState
      isModuleFrameUndamagedNote
      isModuleGlassUndamagedState
      isModuleGlassUndamagedNote
      isBacksheetUndamagedState
      isBacksheetUndamagedNote
      isModuleNotShadedState
      isConnectorProperlyPluggedState
      isConnectorProperlyPluggedNote
      isConnectorSealingIntactState
      isConnectorSealingIntactNote
      isCableRoutedThroughChimneyState
      isCableRoutedThroughChimneyNote
      isChimneySweeperApprovalGivenState
      isChimneySweeperApprovalGivenNote
      isWallPassageProperlySealedState
      isWallPassageProperlySealedNote
      isModuleNotShaded2State
      isModuleNotShadedNote
      areModuleClampsNotTwistedState
      areModuleClampsNotTwistedNote
      isModuleNotShaded2State
      isChimneySweeperApprovalGiven2State
      isChimneySweeperApprovalGiven2Note
    }
    measurement {
      measurementApprovementNote
      completed {
        type
        value
      }
      roofType
      pitchedRoof {
        conditionState
        bricksPlasteredState
        cardboardPoppetState
        notes
        rafterVisibleState
        rafterWidth
        rafterGap
        rafterNotes
        eavesHeight1
        eavesHeight2
        rafterInsulationState
        rafterInsulationNotes
        lightningProtectionState
        integrateLightningProtectionState
        bricksRemoveableState
        bricksRemoveableNotes
        roofPitchInDegrees
        outerRoofNotes
        bricksConditionState
        brickManufacturer
        brickType
        brick {
          id
          manufacturer
          type
        }
        tinBricksRequiredState
        bricksNotes
        roofSidesAssignedState
        roofSidesMeasurements
        roofSidesShadowsState
        roofSidesNotes
        scaffoldingPossibleState
        scaffoldingPossibleNotes
        rainPipeMaterialType
        rssUsableState
        rssUsableNotes
        roadBlockRequiredState
        overheadLineState
        roofAccessNotes
      }
      ac {
        hakLocationState
        hakAndZsSameRoomState
        hakLockedState
        hakToZsDistance
        fireServiceSwitchPresentState
        hakNotes
        zsLocationState
        newZsRequiredState
        zsSubDistributionRequiredState
        zsSubDistributionRequiredNotes
        netType
        zsNotes
        electricMeterCount
        electricMeterList
        intermediateMeterRequesteddState
        meterCombinationOrCascadeRequestedState
        electricMeterOptionalNotes
        acInstallationLocationState
        mateboxToZsDistance
        breakthroughCountState
        cabelPathDescription
        locationAndCablePathNotes
        equipotentialBusBarPresentState
        earthingPresentState
        earthinghNotes
        internetConnectionPresentState
        lanConnectionPresentState
        internetConnectionNotes
        wallboxRequiredState
        heavyCurrentPresentState
        wallboxWirelessInstallationPossibleState
        wallboxWirelessInstallationPossibleNotes
        wallboxCablePathNotes
        wallboxNotes
      }
      customer {
        customerQuestions
        customerWorking
        customerSignature
      }
      flatRoof {
        buildingHeight
        roofDimension
        flatRoofPitchInDegrees
        flatRoofingMaterialType
        flatRoofingMaterialNotes
        atticPresentState
        atticPresentNotes
        flatRoofNotes
      }
    }
    registrations {
      id
      number
      referenceId
      type
      state
      createdAt
      powerCompany {
        company
      }
    }
    netRegistrationRequirements {
      name
      fullfilledState
    }
    operationRegistrationRequirementsFullfilledState
    operationRegistrationRequirements {
      name
      fullfilledState
    }
    itemPlanningFinishedState
    netRegistrationSentAt
    operationRegistrationSentAt
    injectionConfirmationReceivedAt
    completionMessageSentAt
    reportAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    mandateAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    mandateAttachmentsZip {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    mandateAttachmentsZipBuildState
    overviewSchematicAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    overviewSchematicAttachmentsZip {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    overviewSchematicAttachmentsZipBuildState
    layoutPlanAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    layoutPlanAttachmentsZipBuildState
    layoutPlanAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    projectDescriptionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    projectDescriptionAttachmentsZipBuildState
    projectDescriptionAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installationImageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installationImageAttachmentsZipBuildState
    installationImageAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installationTeamDocumentationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installationTeamDocumentationAttachmentsZipBuildState
    installationTeamDocumentationAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    netRegistrationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    netRegistrationAttachmentsZipBuildState
    netRegistrationAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationAttachmentsZipBuildState
    operationRegistrationAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationImageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationImageAttachmentsZipBuildState
    operationRegistrationImageAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    technicalReviewAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    netRegistrationAttachmentsGenerated {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    netRegistrationAttachmentsGeneratedZipBuildState
    netRegistrationAttachmentsGeneratedZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    combinedNetRegistrationAttachmentsZipBuildState
    combinedNetRegistrationAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
      createdAt
    }
    acceptanceReportImageAttachmentsZipBuildState
    acceptanceReportImageAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationAttachmentsGenerated {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationAttachmentsGeneratedZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    operationRegistrationAttachmentsGeneratedZipBuildState
    overviewSchematicAttachmentsGenerated {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    overviewSchematicAttachmentsGeneratedZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    overviewSchematicAttachmentsGeneratedZipBuildState
    miscAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    miscAttachmentsZipBuildState
    miscAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    measurementAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    measurementAttachmentsZipBuildState
    measurementAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    teamDCAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    teamDCAttachmentsZipBuildState
    teamDCAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    teamACAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    teamACAttachmentsZipBuildState
    teamACAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    damageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    damageAttachmentsZipBuildState
    damageAttachmentsZip {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    scaffoldAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    jobSafetyOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ballastAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    brickAndSubstructureOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    railToRoofDistanceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    earthingSubstructureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    brickCoverWidthAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctBrickHandlingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofHookWithEarthingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctCableInputAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    protectedUnderBrickCableRoutingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringPlanAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    allPanelsVisibleAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofTileAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    meterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    presentationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    blueprinAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    salesMiscAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    offerSalesCallOneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    offerSalesCallTwoAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    installationLocationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    planningDdAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    panelBottomViewAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    clampAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    trayfDistanceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    railOverlapAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterReadingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    gakAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterAndGakOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    solarPanelsOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    brickAndSubstructureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    solarPanelAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableManagementAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableAlongFacadeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableBetweenMateboxAndElectricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableManagementOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    equipotentialBusBarAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    inverterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    inverterSerialNumberAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    mateboxSerialNumberAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    storageSerialNumberAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    dongleSerialNumberAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    inverterOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    potentialGroundRodAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    potentialOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    screenshotDeviceFlow {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    screenshotModeOfOperation {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    netConnectionTerms {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    monitoringOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    measurementResultAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    measurementProtocolInclPhotosAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    measurementsOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    storageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    systemPowerOnAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    electricMeterChangeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    signatureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    offerAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    invoiceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    offerAttachmentsZip {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    offerAttachmentsZipBuildState
    invoiceAttachmentsZip {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    projectOfferAttachmentsBuildState
    projectOfferAttachments {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    consumablesListAttachmentsBuildState
    consumablesListAttachments {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    vlinkAttachments {
      id
      path
      filename
      displayFilename
      thumbnail
      size
      mimetype
      zipState
    }
    serviceReportAttachmentsBuildState
    serviceReportAttachments {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    assessmentReportAttachmentsBuildState
    assessmentReportAttachments {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    measurementReportAttachmentsBuildState
    measurementReportAttachments {
      id
      path
      filename
      displayFilename
      size
      mimetype
      zipState
    }
    invoiceAttachmentsZipBuildState

    serviceBrickCoverWidthAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCorrectBrickHandlingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceBrickAndSubstructureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCableAndPlugAttachedToRailStateAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCorrectCableInputAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceRailOverlappingTerminationFittingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceProtectedUnderBrickCableRoutingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceBrickAndSubstructureOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceStringPlanAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceAllPanelsVisibleAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceClampAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceTrayfDistanceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceRailOverlapAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    servicePanelBottomViewAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    solarModuleTypePlateAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    openMateboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    variousBreakthroughsWithPossibleSealingsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableManagementBetweenMateboxAndMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceSolarPanelsOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    installedComponentsInMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    openMeterCabinetPrePostAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    closedMeterCabinetInscribedComponentsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    closedPreMeterAreaWithPlombsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    openHAKAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    breakthroughsWithSealingsMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    closedHAKWithSealAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCableManagementAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCableAlongFacadeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCableManagementOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    photoOfWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    accessCardsOfWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    circuitBreakerAndSafetySwitchForWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    warrantyQCellsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    properSleeveUsedAtRoofPenetrationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    underlaySealedWellAtCableEntryAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    underlayDamagedOrWaterStainsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    labelingOfStringLinesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pvStringCableCorrectCrossSectionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    peCableCorrectlySizedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesProperlySecuredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    crimpingProfessionallyDoneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctSelectionOfPvConnectorsOnStringCableAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    allPlugConnectionsProfessionallyMadeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesNeatlyLaidInsideBuildingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    documentationProjectReportAvailableAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    specialCustomerRequestsDocumentedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    tileTypeAndWidthDocumentedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    replacementTileInstalledAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    lightningProtectionPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    substructureTensionFreeCheckedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    substructureInstalledAsPerReportAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    eachRailSecuredWithMinTwoHooksAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceBetweenHooksMoreThan140cmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctRoofHooksUsedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceRoofHookToTile35mmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    minScrewDistanceToRafterEdge24mmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    roofHookProperlyFixedToRafterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    overhangObservedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    moduleFieldProperlyGroundedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    approvedAuxiliaryRafterUsedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    rafterWidthMeasuredAndComparedToDocsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    noOpenOrDamagedTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    noShiftedOrIncorrectlyLaidTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    tilesProperlyCleanedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctPanHeadScrewsUsedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    minRailOverhangAfterEndClamp20mmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distancesToRidgeEavesNotLessThan20cmAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    moduleClampAreasObservedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    modulesNoDamagesDirtShadingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    moduleClampsProperlyTightenedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    thermalSeparationAfter12mModuleFieldAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    breakthroughProperlySealedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceLightningProtectionToConductivePartsLessThan05mAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    gutterUndamagedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    notBuiltOverRoofOverhangAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableProperlyLaidInChimneyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceToDisturbingSurfaces10cmObservedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableEntryWithReplacementPlateAndFlexedTileAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesDoNotRestOnTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesLaidProtectedUnderTilesAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableEntryOnRoofProperlyDoneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableLaidThroughChimneyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableLaidInProtectiveSleeveAgainstUvRadiationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    peCableProperlySecuredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    documentationProjectReportPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    customerSpecialRequestsConsideredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRooflightningProtectionPresentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    properGroundingFlatRoofSystemAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ballastingAccordingToProjectReportAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    minimumModuleBearingAreaMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    qFlatProperlyConnectedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    distanceToDisturbingSurfacesMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRoofmoduleClampsProperlyTightenedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    modulesNoDamageDirtShadingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    typeOfCableProtectionOutdoorsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctSelectionOfPvConnectorsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesDoNotRestOnRoofAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableEntryDoneProperlyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablesProtectedFromUvAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pvStringCableProperlySizedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringCableLabelingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    peCableProperlySizedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    flatRoofcablesProperlySecuredAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    crimpingProperlyDoneAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    pvSystemLabeledInOrAtDistributionBoardAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    testReportAvailableWithAllMeasurementsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    rcdTypeB300maAvailableInMeterAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringPlanFiledInMeterCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    safetyDistancesMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctLocationForSystemAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctLocationForWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    customerRequestsDocumentedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    slsInstalledAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    componentsDamagedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    crimpingDoneProperlyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ussWiredProperlyInCabinetAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    ussBoxWiredProperlyAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    bendingRadiiMaintainedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctWiringOfMateboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctWiringOfBatteryAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    allComponentsProperlyGroundedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctColorCodingAndLabelingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    correctWiringOfPvConnectorsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cableDuctProperlySizedAndCutAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    nhHakSelectiveToSlsAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    checkNetworkTypeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    earthRodConnectedToHpasAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    connectionBetweenHpasAndPasProperlyMadeAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    insulationProperlyStrippedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    polarityCheckAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stringOpenCircuitVoltageAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    shortCircuitCurrentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    insulationResistanceAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    acceptanceProtocolCustomerSignatureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    cablingBetweenWallboxAndDistributionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serrviceHakLockedAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceHakAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceHakAndZsSameRoomAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceHakOpenAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceZsWithCoverAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceZsWithoutCoverAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceZsSerialNumberAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceZsPostCounterAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceZsPreCounterAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceZsDistributionAreaAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceAdditionalSubDistributionAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceDisassembledMetersAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceMainElectricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceSeconaryElectricMeterAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceInstallationLocationWithInverterAndMateboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceSecondaryInstallationLocationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceCablePath360Attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    mtbxLabelSnAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    invLabelSnAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    batLabelSnAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceOpenMatebox {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceEarthingAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceWallboxLocationAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceWallboxCablePathNotes {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceWallboxAccessCardAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceFiLsWallboxAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    serviceMiscOptionalAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }

    employer {
      id
      number
      name
      electricians {
        name
        id
        number
      }
    }
    client {
      id
      number
      name
      electricians {
        name
        id
        number
      }
      employers {
        name
        id
        number
      }
    }
    installationAddress {
      street
      streetNumber
      streetNumberSuffix
      zip
      city
      cityPart
    }
    customer {
      salutation
      company
      firstname
      lastname
      street
      streetNumber
      streetNumberSuffix
      zip
      city
      cityPart
      phone
      phone2
      phone3
      mobile
      mobile2
      email
      email2
      birthdayAt
      taxNumber
      bank
      iban
      bic
      accountHolder
      field
      fieldSector
      boundary
      electricMeterNumber
      electricMeterNumber2
      electricMeterNumber3
      electricMeterNumberDescription
      electricMeterNumber2Description
      electricMeterNumber3Description
      longitude
      latitude
      createdAt
      updatedAt
    }
    powerCompany {
      company
      _id
      number
      cosinusPhi
      street
      zip
      city
      registrationEmail
      registrationUrl
      description
      website
      operatorOnSiteElectricMeterChange
      powgridRegistrationPerMailState
      powgridRegistrationOnlineState
      netRegistrationDocumentsApprovedState
      operationRegistrationDocumentsApprovedState
      documentAttachments {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      netRegistrationLinkedDocuments {
        id
        name
      }
      operationRegistrationLinkedDocuments {
        id
        name
      }
    }
    measurementPointOperator {
      id
      number
      name
      street
      streetNumber
      zip
      city
      msbNumber
    }
    electrician {
      name
      id
      number
      street
      streetNumber
      zip
      city
      registeredIn
      certificateNumber
      certificateAttachments {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      signatureAttachments {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      stampAttachments {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      contacts {
        firstname
        lastname
        position
        phone
        mobile
        email
      }
      powerCompanies {
        powerCompany {
          number
          _id
          company
        }
        guestEntryNumber
      }
    }
    installation {
      operationRegistrationInstallationAppointment {
        id
        installationTeam
        appointmentApprovedState
        itemsOrderedState
        requiredInstallationTimeinHours
        installationAt
        installationType
        calendarWeek
      }
      description
      electricMeterChangedAt
      operationRegistrationAt
      originalInstallationType
    }
    solarPlant {
      qcellsKit
      guaranteeCode
      extendedCode
      systemNumber
      yearOfConstruction
      solarPanelCount
      emergencyPowerState
      cascadeConnectionState
      networkSupplyManagementState
      networkSupplyManagementPermanentRestrictionState
      networkSupplyManagementXOverState
      netOutputState
      netOutputStates
      storageCouplingState
      electricMeterMountingState
      yearlyPower
      houseFuseState
      electricMeterCombinationState
      electricMeterDeregistrationState
      solarPlantType
      islandOperationIntended
      motorStartup
      networkSupplyType
      measuringConcept
      solarPanel {
        id
        number
        manufacturer
        type
        power
      }
      inverter1 {
        id
        number
        manufacturer
        type
        nominalPower
        nominalVoltage
        apparentPower
        activePower
        ratedCurrent
        shortCircuitCurrent
        nominalDcPower
        allowedChargeDischargeCurrent
        gridAndPlantProtectionIntegrated
        gridAndPlantProtectionType
      }
      inverter2 {
        id
        number
        manufacturer
        type
        nominalPower
        nominalVoltage
        apparentPower
        activePower
        ratedCurrent
        shortCircuitCurrent
        nominalDcPower
        allowedChargeDischargeCurrent
        gridAndPlantProtectionIntegrated
        gridAndPlantProtectionType
      }
      battery {
        id
        number
        manufacturer
        type
        capacity
        dischargePower
        batteryModuleCount
        batteryModuleCapacity
        usableCapacity
        ratedVoltage
        connectionType
      }
      energyFlowDirectionSensor {
        id
        number
        manufacturer
        type
      }
      wallbox {
        id
        number
        manufacturer
        type
        maxNetInputPower
        maxNetOutputPower
        minChargingPower
        maxChargingPower
        nominalDcPower
        activePowerControllable
        chargingType
        connectionType
        connectionTypes
        shiftFactorCosphiType
      }
      storageSystem {
        id
        number
        manufacturer
        type
        batteryCount
        inverterCount
        energyFlowDirectionSensorCount
        battery {
          id
          number
          manufacturer
          type
          capacity
          dischargePower
          batteryModuleCount
          batteryModuleCapacity
        }
        inverter {
          id
          number
          manufacturer
          type
          apparentPower
          activePower
          ratedCurrent
          shortCircuitCurrent
          nominalDcPower
          allowedChargeDischargeCurrent
        }
        energyFlowDirectionSensor {
          id
          number
          manufacturer
          type
        }
      }
    }
    items {
      count
      item {
        _id
        number
        pilatNr
        wholesaleNr
        industryNr
        description
        inventory
        unit
        type
        price
        createdAt
        updatedAt
      }
    }
    enpalReport {
      completed {
        type
        value
      }
      signatureApprovalCustomer
      signatureApprovalTechnician
      signatureOperationCustomer
      signatureOperationTechnician
      scaffoldHeightInMeters
      assignmentType
      firstContractor
      secondContractor
      finalizedBy
      contact
      approvalReportState
      customerInstructionHappened
      customerInstructionHappenedState
      approvalAt
      operationAt
      systemType
      solarSystemType
      existingDamageNotes
      jobSafetyEquipmentUsed
      jobSafetyEquipmentUsedState
      jobSafetyOptionalNotes
      brickAndUkNotes
      brickAndUkInstallationType
      brickAndUkAssemblyType
      ballastRockWeight
      brickAndUkOptionalNotes
      cableAndPlugAttachedToRailState
      railsEarthed
      brickAndUkAttachment_A2
      brickAndUkA2State
      brickAndUk_B
      brickAndUkBState
      brickAndUk_D
      brickAndUkDState
      brickAndUk_F
      brickAndUkFState
      solarPanelNotes
      solarPanel_B2
      solarPanelB2State
      solarPanel_B3
      solarPanelB3State
      solarPanel_B4
      solarPanelB4State
      solarPanel_B5
      solarPanelB5State
      solarPanel_C1
      solarPanelC1State
      solarPanel_D
      solarPanelDState
      solarPanel_E1
      solarPanelE1State
      cableManagementNotes
      cableManagement_A
      cableManagement_B
      cableManagementBState
      cableManagement_C1
      cableManagementC1State
      cableManagement_C2
      cableManagementC2State
      cableManagement_C3
      cableManagementC3State
      cableManagement_Y
      cableManagementYState
      cableManagement_E
      cableManagementEState
      cableManagement_F
      cableManagementFState
      inverterNotes
      inverter_B
      inverterBState
      inverter_D1
      inverterD1State
      inverter_D2
      inverterD2State
      inverter_E
      inverterEState
      inverter_F5
      inverterF5State
      inverter_G
      inverterGState
      inverter_I
      inverterIState
      storageInstalled
      storageInstalledState
      storageNotes
      storage_A
      storageAState
      storage_E
      storageEState
      storage_G
      storageGState
      potentialNotes
      equipotentialBusBar_A1
      equipotentialBusBarA1State
      equipotentialBusBar_B
      equipotentialBusBarBState
      equipotentialBusBar_C
      equipotentialBusBarCState
      electricMeterNotes
      measurementNotes
      monitoringNotes
      electricMeter_B1
      electricMeterB1State
      electricMeter_B2
      electricMeterB2State
      electricMeter_B3
      electricMeterB3State
      electricMeter_C
      electricMeterCState
      electricMeter_I
      electricMeterIState
      electricMeter_I1
      electricMeterI1State
      electricMeterConnectionType
      additionalImagesNotes
      additionalImagesEnpalNotes
      journalMeasurementsStringCount
      journalMeasurements_B
      journalMeasurementsBState
      journalMeasurements_C1
      journalMeasurements_C
      journalMeasurementsCState
      tidiness_A
      tidinessAState
      tidiness_B
      tidinessBState
      electricMeterChange_1
      electricMeterChange1State
      electricMeterChange_2
      electricMeterChange_3
      electricMeterChange_4
      electricMeterChange4State
      electricMeterChange_5
      electricMeterChange_6
      electricMeterChange_7
      electricMeterChange7State
      electricMeterChange_9
      electricMeterChange_10
      electricMeterChange_11
      electricMeterChange_12
      electricMeterChange_13
      electricMeterChange_14
      electricMeterChange_15
      electricMeterChange15State
      electricMeterChange_16
      electricMeterChange16State
      electricMeterChange_17
      electricMeterChange_18
      electricMeterChange18State
      electricMeterChange_19
      electricMeterChange_20
      electricMeterChange20State
      electricMeterChange_21
      electricMeterChangeAt
      electricMeterHasBeenChanged
      electricMeterHasBeenChangedState
      systemPowerOn_1
      systemPowerOn_2
      systemPowerOn_3
      systemPowerOn_4
      systemPowerOn_5
      scaffoldAttachment {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      uploadedSignatureApprovalCustomer {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      uploadedSignatureApprovalTechnician {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      uploadedSignatureOperationCustomer {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      uploadedSignatureOperationTechnician {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      damageAttachments {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_X {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_A {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_C1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_C1_2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_C1_3 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_E {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_G {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      brickAndUkAttachment_H {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachment_A {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachment_B1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachments_C {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachments_E1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachments_E2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachment_F {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      solarPanelAttachment_G {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      cableManagementAttachment_D {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      cableManagementAttachment_X {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_A {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_B {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_C {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_D {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_F {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachmentsAdditional_F {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_G2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      inverterAttachment_H {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_B1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_B2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_C1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_C2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_D {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachments_D1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_F {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      storageAttachment_H {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      equipotentialBusBarAttachment_A {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      equipotentialBusBarAttachment_A2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      equipotentialBusBarAttachment_B1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterAttachment_B {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterAttachment_D {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterAttachment_E {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterAttachment_H {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterAttachment_J {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterAttachment_K {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      additionalImageAttachments {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      journalMeasurementsAttachment_A {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterChangeAttachment_1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterChangeAttachments_2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterChangeAttachment_3 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterChangeAttachment_4 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterChangeAttachment_5 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      electricMeterChangeAttachment_6 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      systemPowerOnAttachment_1 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      systemPowerOnAttachment_2 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
      systemPowerOnAttachment_3 {
        id
        path
        thumbnail
        filename
        displayFilename
        size
        mimetype
      }
    }
  }
`;

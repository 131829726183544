<template>
  <b-navbar class="header d-print-none">
    <b-nav>
      <b-nav-item>
        <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class="la la-bars la-lg" />
        </a>
        <b-tooltip target="barsTooltip" placement="bottom">
          Turn on/off
          <br />sidebar <br />collapsing
        </b-tooltip>
        <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <span class="rounded rounded-lg bg-gray text-white d-md-none">
            <i class="la la-bars la-lg" />
          </span>
          <i class="la la-bars la-lg d-sm-down-none ml-4" />
        </a>
      </b-nav-item>
      <b-nav-item class="d-md-down-none">
        <LoadingSuccessSpinner />
      </b-nav-item>
    </b-nav>

    <a class="navbarBrand d-md-none">
      <img class="mt-1 mr-3 image" src="../../assets/logo2.png" alt="Q Power Solution GmbH" />
    </a>
    <b-nav class="ml-auto">
      <b-nav-item-dropdown
        id="basic-nav-dropdown"
        class="notificationsMenu d-sm-down-none mr-2"
        extra-menu-classes="notificationsWrapper py-0 animated animated-fast fadeInUp"
        right
      >
        <template slot="button-content" class="header-avatar-image">
          <span class="avatar rounded-circle thumb-sm float-left mr-2">
            <img
              v-if="user && user.avatar"
              class="rounded-circle"
              :src="user.avatar.path"
              alt="..."
            />
            <span v-else>{{ firstUserLetter }}</span>
          </span>
          <i v-if="hasNotifications" class="notification-point animated bounceIn"></i>
          <span v-if="user" class="small">{{ user.firstname || user.email }}</span>
        </template>
        <Notifications />
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Notifications from '@/components/Notifications/Notifications';
import LoadingSuccessSpinner from '@/components/LoadingSuccessSpinner.vue';

export default {
  name: 'Header',
  components: { LoadingSuccessSpinner, Notifications },
  data() {
    return {};
  },
  computed: {
    ...mapState('layout', [
      'sidebarClose',
      'sidebarStatic',
      'chatNotificationIcon',
      'chatNotificationPopover',
    ]),
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters(['getUser']),
    firstUserLetter() {
      if (this.user) {
        return (this.user.firstname || this.user.email)[0].toUpperCase();
      }
      return '';
    },
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
    hasNotifications() {
      return (
        (this.user && this.user.postNotifications && this.user.postNotifications.length > 0) ||
        (this.user && this.user.taskNotifications && this.user.taskNotifications.length > 0)
      );
    },
  },
  methods: {
    ...mapActions('layout', [
      'toggleSidebar',
      'toggleChat',
      'switchSidebar',
      'changeSidebarActive',
      'initApp',
    ]),
    ...mapActions('auth', ['refreshUser']),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    async reload() {
      // this.$router.go()
      // await this.refreshUser();
    },
  },
  created() {
    if (window.innerWidth > 576) {
      this.initApp();
    }
    // this.user = this.getCurrentUser()
  },
};
</script>

<style src="./Header.scss" lang="scss" scoped />

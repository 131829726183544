import gql from 'graphql-tag';
import apolloClient from '../apollo';
import Vue from 'vue';

/**
 * State
 */
const getDefaultState = () => {
  return {
    policies: [],
    policyCount: 0,
    userPolicies: [], // Separate storage for user-specific policies
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for a policy
 * used for all queries/mutations which return a policy
 * @type {DocumentNode}
 */
const POLICY_REQUEST_FIELDS = gql`
  fragment policyFields on Policy {
    _id
    number
    description
    effect
    priority
    userConditions
    resourceConditions
    actionConditions
    contextConditions
    isActive
    createdAt
    updatedAt
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} policy
 */
const createPayload = (policy) => {
  // console.log('createPayload()', policy);

  // Create a clean payload from the policy object
  const payload = {
    name: policy.name,
    description: policy.description,
    effect: policy.effect,
    priority: policy.priority,
    userConditions: policy.userConditions,
    resourceConditions: policy.resourceConditions,
    actionConditions: policy.actionConditions,
    contextConditions: policy.contextConditions,
    // isActive: policy.isActive,
  };

  // console.log('createPayload()', payload);

  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchPolicies({ commit, dispatch }, { pagination, sorting, filters }) {
    console.log('fetchPolicies()', pagination, sorting, filters);
    try {
      const response = await apolloClient.query({
        query: gql`
          query policies(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: PolicyFilterInput
          ) {
            policies(pagination: $pagination, sorting: $sorting, filters: $filters) {
              policies {
                ...policyFields
              }
              policyCount
            }
          }
          ${POLICY_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      commit('SET_POLICIES', response.data.policies.policies);
      commit('SET_POLICY_COUNT', response.data.policies.policyCount);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Fetch policies applicable to the current user
   * This should be called right after login to load permission data
   * @todo create dedicated resolver
   */
  async fetchUserPolicies({ commit, rootGetters }) {
    try {
      const currentUser = rootGetters['auth/getCurrentUser'];
      if (!currentUser || !currentUser.authorizationAttributes.role) {
        console.warn('fetchUserPolicies: No user or user role available');
        return;
      }

      console.log('fetchUserPolicies() for', currentUser.email);

      const pagination = {
        skip: 0,
        pageSize: 100,
      };

      const sorting = {
        sortField: 'number',
        sortOrder: -1,
      };

      // Set filters to get policies relevant to this user
      const filters = {
        userNumber: currentUser.userNumber,
      };

      // Load all policies that could apply to this user
      const response = await apolloClient.query({
        query: gql`
          query userPolicies(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: PolicyFilterInput
          ) {
            policies(pagination: $pagination, sorting: $sorting, filters: $filters) {
              policies {
                ...policyFields
              }
              policyCount
            }
          }
          ${POLICY_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only', // Don't use cache for permissions
      });

      // Store user policies in separate state array
      commit('SET_USER_POLICIES', response.data.policies.policies);

      // Also cache policies in localStorage for faster loading on page refresh
      localStorage.setItem('userPolicies', JSON.stringify(response.data.policies.policies));

      console.log(
        `Loaded ${response.data.policies.policies.length} policies for user ${currentUser.email}`
      );

      return response.data.policies.policies;
    } catch (err) {
      console.error('Error fetching user policies:', err);
      // Don't throw error to avoid breaking auth flow
      return [];
    }
  },

  async createPolicy({ commit, dispatch }, policy) {
    const payload = createPayload(policy);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createPolicy($payload: PolicyInput!) {
            createPolicy(policyInput: $payload) {
              ...policyFields
            }
          }
          ${POLICY_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch(
        'message',
        {
          message: 'Policy created successfully!',
          type: 'success',
          lifetime: 2,
        },
        { root: true }
      );
      commit('ADD_OR_UPDATE_POLICY', response.data.createPolicy);
      return response.data.createPolicy._id;
    } catch (err) {
      dispatch(
        'message',
        {
          message: 'Error creating policy! Please check input fields!',
          type: 'danger',
          lifetime: 1,
        },
        { root: true }
      );
      throw new Error(err);
    }
  },

  async updatePolicy({ commit, dispatch }, policy) {
    const payload = createPayload(policy);
    // console.log('updatePolicy()', payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updatePolicy($policyNumber: Int!, $payload: PolicyInput!) {
            updatePolicy(policyNumber: $policyNumber, policyInput: $payload) {
              ...policyFields
            }
          }
          ${POLICY_REQUEST_FIELDS}
        `,
        variables: {
          policyNumber: policy.number,
          payload: payload,
        },
      });
      dispatch(
        'message',
        {
          message: 'Policy updated successfully!',
          type: 'success',
          lifetime: 2,
        },
        { root: true }
      );
      commit('ADD_OR_UPDATE_POLICY', response.data.updatePolicy);
      return response.data.updatePolicy._id;
    } catch (err) {
      dispatch(
        'message',
        {
          message: 'Error updating policy! Please check input fields!',
          type: 'danger',
          lifetime: 1,
        },
        { root: true }
      );
      throw new Error(err);
    }
  },

  async deletePolicy({ commit, dispatch }, policyNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deletePolicy($policyNumber: Int!) {
            deletePolicy(policyNumber: $policyNumber)
          }
        `,
        variables: {
          policyNumber: policyNumber,
        },
      });
      dispatch(
        'message',
        {
          message: 'Policy deleted successfully!',
          type: 'success',
          lifetime: 2,
        },
        { root: true }
      );

      // Remove the policy from the store
      commit('REMOVE_POLICY', policyNumber);
      return response.data.deletePolicy;
    } catch (err) {
      dispatch(
        'message',
        {
          message: 'Error deleting policy!',
          type: 'danger',
          lifetime: 1,
        },
        { root: true }
      );
      throw new Error(err);
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_POLICIES: (state, policies) =>
    policies ? (state.policies = policies) : (state.policies = []),
  SET_USER_POLICIES: (state, policies) =>
    policies ? (state.userPolicies = policies) : (state.userPolicies = []),
  ADD_POLICY: (state, policy) => state.policies.push(policy),
  ADD_OR_UPDATE_POLICY: (state, updatedPolicy) => {
    const existingPolicy = state.policies.find((policy) => policy.number === updatedPolicy.number);
    if (existingPolicy) {
      Object.assign(existingPolicy, updatedPolicy);
    } else {
      state.policies.push(updatedPolicy);
    }
  },
  SET_POLICY_COUNT: (state, policyCount) => (state.policyCount = policyCount),
  REMOVE_POLICY: (state, policyNumber) =>
    (state.policies = state.policies.filter((policy) => policy.number !== policyNumber)),
};

/**
 * Getters
 */
const getters = {
  getPolicies: (state) => {
    return state.policies;
  },
  getUserPolicies: (state) => {
    return state.userPolicies;
  },
  getPolicy: (state) => (policyId) => state.policies.find((policy) => policy._id == policyId),
  getPolicyByNumber: (state) => (policyNumber) =>
    state.policies.find((policy) => policy.number == policyNumber),
  getPolicyCount: (state) => state.policyCount,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

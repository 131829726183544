export const hasRole = (user, requiredRole) => {
  if (requiredRole === 'ADMIN') {
    return user.authorizationAttributes.role === 'ADMIN';
  }
  if (requiredRole === 'EMPLOYER') {
    return (
      user.authorizationAttributes.role === 'ADMIN' ||
      user.authorizationAttributes.role === 'EMPLOYER'
    );
  }
  return true;
};

/**
 * Determine if a user is a customer
 * @todo consolidate with same function in auth.js
 * @param {*} user
 */
// export const isEmployer = (user) => user.role === 'EMPLOYER';

/**
 * check if user has one of the required roles
 * @param {*} user
 * @param {*} authorizedRoles
 * @param {*} companyContextSlug
 * @returns
 */
export const authorize = (user, authorizedRoles, routeSlug) => {
  // if user is a customer he can only access resources of his own company
  // if (
  //   (user.role === 'EMPLOYER') &&
  //   user.company.slug != routeSlug
  // ) {
  //   return false;
  // }

  // check if user has one of the required roles
  let authorized = false;
  authorizedRoles.map((role) => {
    if (role === user.role) {
      authorized = true;
    }
  });

  return authorized;
};

/**
 * check if the users email matches one of the authorizedUsers
 * @param {*} user
 * @param {*} authorizedUsers
 * @returns
 */
export const authorizeByUser = (user, authorizedUsers) => {
  let authorized = false;
  authorizedUsers.map((email) => {
    if (email === user.email) {
      authorized = true;
    }
  });

  return authorized;
};

/**
 * Checks if a user has permission to access a resource based on their policies
 *
 * @param {Object} user - The user requesting access
 * @param {String} resourceType - The type of resource being accessed
 * @param {Array} requiredActionPermissions - The permissions required to access the resource
 * @returns {Boolean} - Whether the user has permission to access the resource
 */
export const hasResourcePermission = (user, resourceType, requiredActionPermissions) => {
  // Get userPolicies from the Vuex store
  const userPolicies = require('@/store').default.getters['policies/getUserPolicies'];

  console.log(
    'hasResourcePermission',
    user.email,
    user.authorizationAttributes.role,
    user.authorizationAttributes.department,
    resourceType,
    requiredActionPermissions,
    userPolicies
  );

  // Admin sees everything
  if (user && user.role === 'ADMIN') return true;

  // If no resource type or permissions specified, do not allow access
  if (!resourceType || !requiredActionPermissions || requiredActionPermissions.length === 0) {
    return false;
  }

  // If no policies exist yet, deny access
  if (!userPolicies || userPolicies.length === 0) {
    return false;
  }

  // Find policies that match this user's role and the specified resource
  const matchingPolicies = userPolicies.filter((policy) => {
    // Check if policy is active
    // if (!policy.isActive) return false;

    // Check if policy applies to this user's role
    if (
      policy.userConditions &&
      policy.userConditions.role &&
      policy.userConditions.role !== user.authorizationAttributes.role
    ) {
      return false;
    }

    // Check if policy applies to this user's department
    if (
      policy.userConditions &&
      policy.userConditions.department &&
      policy.userConditions.department !== user.authorizationAttributes.department
    ) {
      return false;
    }

    // Check if policy applies to the requested resource type
    if (
      policy.resourceConditions &&
      policy.resourceConditions.resourceType &&
      policy.resourceConditions.resourceType !== resourceType
    ) {
      return false;
    }

    return true;
  });

  console.log('matchingPolicies', matchingPolicies);

  const authorized = matchingPolicies.some((policy) => {
    if (!policy.actionConditions) return false;

    return requiredActionPermissions.some((permission) =>
      policy.actionConditions.includes(permission)
    );
  });
  console.log('hasResourcePermission() - authorized', authorized);
  // Check if any matching policy grants any of the required action permissions
  return authorized;
};
